<template>
  <div class="container">
    <div class="content-box">
      <h1>Sign up</h1>

      <div>
        The game is still in development. Join our exclusive mailing list and be among the first
        ones to join the <strong class="highlighted-text">early access</strong> stage and get
        additional perks when it's fully released!
      </div>

      <div id="mc_embed_signup" class="mailchimp">
        <form
          action="https://network.us18.list-manage.com/subscribe/post?u=d3775b9f1e66db64d75c9d48f&amp;id=8e3a4066d8"
          method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
          class="validate"
          target="_blank" novalidate
          @submit="reportConversion">
          <div id="mc_embed_signup_scroll">
            <input v-model="email" type="email" name="EMAIL" class="email" id="mce-EMAIL"
                   autocomplete="on" required>
            <div class="mailchimp-hidden-input" aria-hidden="true">
              <input type="text"
                     name="b_d3775b9f1e66db64d75c9d48f_8e3a4066d8"
                     tabindex="-1"
                     value="">
            </div>
            <div class="mailchimp-error-message" v-if="showInvalidMessage">Please enter valid email address.</div>
            <div class="optionalParent">
              <div class="clear foot">
                <input type="submit" value="Sign Up" name="subscribe" id="mc-embedded-subscribe"
                       class="button" :disabled="!email">
              </div>
            </div>
          </div>
        </form>
      </div>

      <!--End mc_embed_signup-->
    </div>
  </div>
</template>

<script>
const EMAIL_REGEXP = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
export default {
  name: 'News',
  metaInfo () {
    return {
      title: 'Sign Up',
      link: [
        { rel: 'canonical', href: 'https://guildsandblades.com/subscribe' }
      ]
    }
  },
  data () {
    return {
      email: '',
      showInvalidMessage: false
    }
  },
  computed: {},
  methods: {
    reportConversion (event) {
      if (!this.email || !EMAIL_REGEXP.test(this.email)) {
        this.showInvalidMessage = true
        event.preventDefault()
        return false
      }

      this.showInvalidMessage = false

      window.gtag('event', 'conversion', {
        send_to: 'AW-970191133/SgA-CMO-56MDEJ3iz84D'
      })

      return true
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/variables.scss';

.mailchimp {
  margin-top: $spacing1;

  @media(min-width: $breakpointDesktopLg) {
    max-width: 430px;
  }

  &-hidden-input {
    position: absolute;
    left: -5000px;
  }

  &-error-message {
    margin-top: 10px;
    padding: 5px;
    border-radius: 5px;
    background-color: $colorHighlight;
  }

  input[type=email] {
    width: 100%;
    padding: $spacing1;
    margin: $spacing1 0;
    box-sizing: border-box;

    border: 1px solid $colorLink;
    border-radius: 5px;

    background-color: $colorBackground;
    color: $colorContent;
    font-size: 18px;
    font-family: $fontFamilyBase;

    &:focus {
      border: 1px solid $colorLinkActive;
    }
  }

  input[type=submit] {
    color: $colorLink;
    border-radius: 50px;
    background-color: $colorDarker;

    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
    font-family: $fontFamilyHeadings;
    font-weight: bold;

    border: 1px solid $colorLink;

    text-align: center;
    text-decoration: none;
    padding: $spacing1;

    text-shadow: none;

    box-sizing: border-box;
    cursor: pointer;
    font-size: 100%;
    line-height: 1.15;
    margin: 12px 0 0;
    outline: none;
    overflow: hidden;
    touch-action: manipulation;
    width: 100%;

    &:focus {
      border: 1px solid $colorLink;
    }

    &:hover {
      color: $colorLinkHover;
      border: 1px solid $colorLinkHover;
      transition-property: color, border;
      transition-duration: 0.2s;
    }

    &:active {
      color: $colorLinkActive;
      border: 1px solid $colorLinkActive;
      transition-property: color, border;
      transition-duration: 0.2s;
    }

    &:disabled {
      color: $colorContent;
      border: $colorContent;
      cursor: not-allowed;
    }
  }

}
</style>
